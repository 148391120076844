import React, {useContext} from 'react';
import {formatDate, formatDuration, getStatusRender, MAX_TIME_MS, prepareDataPoints} from "./Utils";
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {ResponsiveContext} from "./ResponsiveContext";
import ChannelStatus from './ChannelStatus';
import Skeleton from "@mui/material/Skeleton";
import {reverse, take} from "lodash";

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length) {
        const duration = payload[0].value;

        return <div className='tool-tip-container'>
                    <span className='tool-tip-span'>
                        {`${label} hs: `}
                    </span>
            {`${Math.floor(duration)} millis`}
            <ChannelStatus duration={duration}/>
        </div>;
    }
    return null;
};

const Logs = ({loading, points, l}) => <div className='logs-container'>
    <span className='logs-span'>{l('recentLogs')}</span>

    {loading && [1, 2, 3].map(i => <Skeleton key={i} className='skeleton'
                                             variant="rectangular"
                                             width={'100%'} height={20}/>)}

    {!loading && <div className='logs-output'>
        {take(reverse(points.filter(p => p.v > 0)), 10).map((p, i) => {
            return <div key={'log_' + i}>
                <span className='log-response'> {formatDate(p.iso)} </span>
                {formatDuration(p.v)}
            </div>
        })}
    </div>}
</div>;

const Histogram = ({
                       channel,
                       maxAxisY = MAX_TIME_MS,
                       width = '100%',
                       height = 200,
                       fontSize = 12,
                       showLegend = true,
                       showTooltip = false,
                   }) => {

    const {l} = useContext(ResponsiveContext);
    const metrics = channel.metrics || [];
    const userSentMetricsPoints = metrics.filter(v => v.metricName === 'user')[0]?.points || null;

    const formatYLabel = v => {
        const yIsMilliSeconds = true;
        if (yIsMilliSeconds) {
            return (v / 1000).toFixed(1) + ' ' + l('secs');
        }
        return v.toFixed(1);
    };

    return <div className='accordion-details'>
        <div className='accordion-details-title'>
            <div>
                {metrics
                    .map((metric, idx) => {
                        const points = prepareDataPoints(metric.points || [], maxAxisY);

                        return {metric, points, idx};
                    })
                    .map(({metric, points, idx}) => <span key={'mi_' + idx} style={{display: 'flex', width, height, fontSize}}>
                    <ResponsiveContainer className="container" width="100%" height="100%">
                        <BarChart data={points}>
                            <span className="tooltiptext">_</span>

                            {showLegend && <XAxis dataKey="time"/>}
                            <YAxis hide={!showLegend} tickFormatter={formatYLabel}/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            {showLegend && <Legend verticalAlign="top"/>}
                            {showTooltip && <Tooltip content={<CustomTooltip/>}/>}
                            <Bar
                                name={metric.label}
                                dataKey='value1'
                                fill={'#44A134'}
                            >
                            {points.map((entry, index) => (<Cell cursor="pointer" fill={getStatusRender('colorText', entry.value1)} key={`cell-${index}`}/>))}
                            </Bar>
                        </BarChart>
                  </ResponsiveContainer>
                </span>)}
            </div>
        </div>

        {userSentMetricsPoints && <Logs l={l} points={userSentMetricsPoints}/>}
    </div>;
};

export default Histogram;

import React, {useContext} from 'react';
import {getStatusRender, MAX_TIME_MS, prepareDataPoints, quantile} from "./Utils";
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {ResponsiveContext} from "./ResponsiveContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";

const OtherMetrics = ({
                          metrics,
                          metricSpec,
                          maxAxisY = MAX_TIME_MS,
                          width = '100%',
                          height = 200,
                          fontSize = 12,
                          showLegend = true
                      }) => {

    const {name, label, otherLabel, yIsMilliSeconds, desc} = metricSpec;
    const {isLargeScreen, isMediumScreen, isExtraSmallScreen, l} = useContext(ResponsiveContext);

    const [metric] = (metrics?.othersValues || []).filter(v => v.metricName === name);

    if (!metric)
        return null;

    const formatYLabel = v => {
        if (yIsMilliSeconds) {
            return (v / 1000).toFixed(1) + ' ' + l('secs');
        }
        return v.toFixed(0);
    };

    const points = prepareDataPoints(metric?.points, maxAxisY);
    const otherPoints = metric?.otherPoints ? prepareDataPoints(metric?.otherPoints, maxAxisY) : null;

    if (otherPoints?.length) {
        for (const otherPoint of otherPoints) {
            points.filter(p => p.time === otherPoint.time).forEach(p => p.value2 = otherPoint.value1);
        }
    }

    const showExpansion = isMediumScreen || isLargeScreen;

    const _95p = quantile(points.map(p => p.value1), .95);

    let okStatus = true;
    if (points.length > 5) {
        const _95pStatus = getStatusRender('label', _95p, metricSpec.problemsValue);

        if (_95pStatus === l('statusDelays')) {
            okStatus = false;
        }
    }

    return <Accordion elevation={1}
                      disableGutters={true}
                      expanded={showExpansion}
                      className={`${okStatus ? 'card-ok' : 'card-problems'} accordion-container`}>

        {/*<AccordionSummary expandIcon={showExpansion && <ExpandMore/>}>*/}
        <AccordionSummary>
            <span style={{
                display: 'flex',
                alignItems: 'center',
                gap: isExtraSmallScreen ? '0.6em' : '',
                justifyContent: 'space-between',
                width: '100%',
                flexDirection: isExtraSmallScreen ? 'column' : 'row'
            }}>

                <div className='box-title'>
                    {okStatus && <CheckCircle className='ok-color'/>}
                    {!okStatus && <Warning className='problems-color'/>}

                    <span className='bold'>{label}</span>
                </div>

                <span title={l('percentile95')} className='metric-summary'>{formatYLabel(_95p)}</span>
            </span>
        </AccordionSummary>

        {showExpansion &&
            <AccordionDetails>
                {desc && <div><span style={{fontSize: '0.9em'}}>{desc}</span></div>}
                <br/>

                <div className='accordion-details'>
                    <div className='accordion-details-title'>
                        <span style={{display: 'flex', width, height, fontSize}}>
                            <ResponsiveContainer className="container" width="100%" height="100%">
                                    <BarChart data={points}>
                                        <span className="tooltiptext">Tooltip text</span>
                                        {showLegend && <XAxis dataKey="time"/>}
                                        {/*<XAxis minTickGap={0} angle={45} textAnchor='start'/>*/}
                                        <YAxis hide={!showLegend} tickFormatter={formatYLabel}/>
                                        {/*<YAxis hide={!showLegend} domain={[0, getMax(points) + maxValueToAdd]}/>*/}
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        {showLegend && <Legend verticalAlign="top"/>}
                                        <Bar
                                            name={label}
                                            dataKey='value1'
                                            stackId={otherPoints?.length ? 'a' : null}
                                            fill={'#44A134'}
                                            // fillOpacity={1} fill='url(#colorDefinition)'
                                        >
                                            {points.map((entry, index) => (
                                                <Cell cursor="pointer" fill={getStatusRender('colorText', entry.value1, metricSpec.problemsValue)}
                                                      key={`cell-${index}`}/>
                                            ))}
                                        </Bar>

                                        {otherPoints?.length && <Bar name={otherLabel} dataKey="value2" stackId="a" fill="#D0021B"/>}
                                    </BarChart>
                            </ResponsiveContainer>
                        </span>
                    </div>
                </div>
            </AccordionDetails>}
    </Accordion>;
};

export default OtherMetrics;

import { STATUS_COLOR_TEXTS } from "../../Utils";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  Cell,
  Legend,
} from "recharts";
import "./UptimeChart.css";

const PERCENT_TO_FAIL = 0.95;

const UptimeChart = ({
  points,
  width = "100%",
  height = 80,
  fontSize = 12,
  showLegend = true,
}) => {
  const okAmount = points.filter((obj) => obj.v === 1).length;
  const percentAmount = ((okAmount / points.length) * 100).toFixed(2);
  const okStatus = okAmount >= PERCENT_TO_FAIL * points.length;

  return (
    <span
      style={{
        display: "flex",
        width,
        height: showLegend ? height + 20 : height,
        fontSize,
      }}
    >
      <ResponsiveContainer className="container" width="100%" height="100%">
        <BarChart data={points}>
          <XAxis dataKey="dateToShow" />
          {showLegend && <Legend verticalAlign="top" />}
          <Bar
            name={`Uptime ${percentAmount}%`}
            dataKey="one"
            fill={STATUS_COLOR_TEXTS[okStatus ? 0 : 2]}
          >
            {points.map((entry, index) => (
              <Cell
                cursor="default"
                fill={STATUS_COLOR_TEXTS[entry.v === 1 ? 0 : 2]}
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </span>
  );
};

export default UptimeChart;

import {useContext} from "react";
import {ResponsiveContext} from "../ResponsiveContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";
import UptimeChart from "./charts/UptimeChart";
import {formatDateHHMM} from "../Utils";
import "./Uptime.css";

const PERCENT_TO_FAIL = 0.95;

const Uptime = ({metric}) => {
    const {isLargeScreen, isMediumScreen, isExtraSmallScreen, l} =
        useContext(ResponsiveContext);
    const {label, points, desc} = metric;

    if (!metric) return null;

    const showExpansion = isMediumScreen || isLargeScreen;

    const okAmount = points.filter((obj) => obj.v === 1).length;
    const percentAmount = ((okAmount / points.length) * 100).toFixed(2);
    const okStatus = okAmount >= PERCENT_TO_FAIL * points.length;

    points.forEach((obj) => {
        obj.dateToShow = formatDateHHMM(obj.iso);
        obj.one = 1;
    });

    return (
        <Accordion
            elevation={1}
            disableGutters={true}
            expanded={true}
            className={`${
                okStatus ? "card-ok" : "card-problems"
            } accordion-container`}
        >
            <AccordionSummary
                style={{
                    cursor: "default",
                }}
            >
        <span
            style={{
                display: "flex",
                alignItems: "center",
                gap: isExtraSmallScreen ? "0.6em" : "",
                justifyContent: "space-between",
                width: "100%",
                flexDirection: isExtraSmallScreen ? "column" : "row",
            }}
        >
          <div className="box-title">
            {okStatus && <CheckCircle className="ok-color"/>}
              {!okStatus && <Warning className="problems-color"/>}

              <span className="bold">{label}</span>
          </div>

          <span title={l("percentile95")} className="metric-summary">
            Uptime {percentAmount}%
          </span>
        </span>
            </AccordionSummary>

            {showExpansion && (
                <AccordionDetails>
                    {desc && <div><span style={{fontSize: '0.9em'}}>{desc}</span></div>}
                    <br/>

                    <div className="accordion-details">

                        <div className="accordion-details-title">
                            <UptimeChart points={points} label={label} showLegend={false}/>
                        </div>
                    </div>
                </AccordionDetails>
            )}
        </Accordion>
    );
};

export default Uptime;

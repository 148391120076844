import React, {useEffect, useState} from "react";
import Skeleton from "@mui/material/Skeleton";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
// import StatusCard from './StatusCard';
import OtherMetrics from "./OtherMetrics";
import Channel from "./Channel";
import Uptime from "./components/Uptime";
import {getLang, l} from "./Lang";
import {ResponsiveContext} from "./ResponsiveContext";
import {getAuth, getMetrics} from "./Utils";
import ChannelWebchat from "./components/ChannelWebchat";
import MonthlyUptime from "./components/MonthlyUptime";

const REFRESH_TIME = 30000;
// const REFRESH_TIME = 9999930000; // NO COMMMIT!!

const {DateTime} = require("luxon");

const App = () => {
    const [businessId, setBusinessId] = useState(null);
    const [businessName, setBusinessName] = useState("");
    const [metrics, setMetrics] = useState({});

    // const [channel, setChannel] = useState(null);
    // const [fromDate, setFromDate] = useState(null);
    // const [toDate, setToDate] = useState(null);
    // const [range, setRange] = useState("Últimos 30 min");
    // const [open, setOpen] = useState(false);

    const useMedia = (query) => {
        const [matches, setMatches] = useState(window.matchMedia(query).matches);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) setMatches(media.matches);

            const listener = () => setMatches(media.matches);

            media.addListener(listener);

            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const authToken = urlSearchParams.get("t") || null;
        const desiredBid = urlSearchParams.get("bid") || null;

        Promise.allSettled([
            getAuth(authToken, desiredBid, getLang())
                .then((raw) => {
                    if (raw.data.redirect) {
                        window.location = raw.data.redirect;
                        return;
                    }

                    const businessId = raw.data.businessId;

                    setBusinessId(businessId);
                    setBusinessName(raw.data.name);

                    return getMetrics(businessId, getLang(), raw.data.authToken || null);
                })
                .then((metrics) => {
                    setMetrics(metrics);
                }),
        ]).catch((err) => console.error(err.stack, err));
    }, []);

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    //     setChannel(null);
    //     setFromDate(null);
    //     setToDate(null);
    //     setRange("Últimos 30 min");
    // };

    useEffect(() => {
        const intervalId = setInterval(() => {
            //assign interval to a variable to clear it.

            getMetrics(businessId, getLang()).then((metrics) => {
                setMetrics(metrics);
            });
        }, REFRESH_TIME);

        return () => clearInterval(intervalId);
    }, [businessId]);

    const isLargeScreen = useMedia("(min-width: 800px)");
    const isMediumScreen = useMedia("(max-width: 799px) and (min-width: 650px)");
    const isSmallScreen = useMedia("(max-width: 649px) and (min-width: 450px)");
    const isExtraSmallScreen = useMedia("(max-width: 449px)");

    const channels = metrics?.channels || [];
    const loading = Object.keys(metrics).length === 0;
    const monitorActive = loading || channels.length > 0;
    const refreshTime = !metrics?.refreshTime
        ? null
        : DateTime.fromISO(metrics?.refreshTime);

    const metricIsWebchat = (metric) =>
        (metric.metricName && metric.metricName.includes("webchat")) ||
        (metric.subType && metric.subType.includes("webchat")) ||
        (metric.label && metric.label.includes("webchat"));

    const noMetrics =
        !metrics ||
        ((!metrics.channelMetrics || metrics.channelMetrics.length === 0) &&
            (!metrics.uptime || metrics.uptime.length === 0) &&
            (!metrics.othersValues || metrics.othersValues.length === 0));


    return (
        <ResponsiveContext.Provider
            value={{
                isLargeScreen,
                isMediumScreen,
                isSmallScreen,
                isExtraSmallScreen,
                l,
            }}
        >
            <Container maxWidth="lg">
                <Paper className="main-container" elevation={3}>
                    <div className="header">
                        <h1>{l("title") + (businessName ? ` - ${businessName}` : "")}</h1>
                        {refreshTime && (
                            <p style={{fontSize: "0.8em"}}>
                                {l("lastUpdated")}{" "}
                                {refreshTime.toLocaleString(DateTime.DATE_MED) +
                                    " " +
                                    refreshTime.toLocaleString(DateTime.TIME_WITH_SECONDS)}
                            </p>
                        )}
                        {/*<p style={{fontSize: "0.9em"}}>{l('subTitle')}</p>*/}
                    </div>

                    {loading && <LinearProgress/>}

                    {!loading && noMetrics && (
                        <div className="no-metrics">
                            <h3>{l("noMetrics")}</h3>
                        </div>
                    )}

                    {!monitorActive && (
                        <div className="no-monitor bold">
                            <span>{l("noMonitor")}</span>

                            <div className="no-monitor-button">
                                <Button
                                    onClick={(_) => {
                                        window.open(
                                            `https://help.botmaker.com/${getLang()}/support`,
                                            "_blank"
                                        );
                                    }}
                                    variant="text"
                                >
                                    Botmaker Help
                                </Button>
                            </div>
                        </div>
                    )}

                    {/*{(!loading && monitorActive && channels.length > 0) && <StatusCard metrics={metrics}/>}*/}

                    {monitorActive && (
                        <div className="report-container">
                            {loading &&
                                [1, 2, 3].map((i) => (
                                    <Skeleton
                                        key={i}
                                        className="skeleton"
                                        variant="rectangular"
                                        width={"100%"}
                                        height={45}
                                    />
                                ))}

                            {(metrics?.channelMetrics || []).map((channel, idx) => (
                                <Channel key={"_" + idx} channel={channel}/>
                            ))}

                            {(metrics?.channels || [])
                                .filter((channel) => channel.platform === "webchat")
                                .map((channel, idx) => (
                                    <ChannelWebchat
                                        key={"_" + idx}
                                        channel={channel}
                                        uptime={metrics?.uptime.find(
                                            (data) => data.subType === channel.id
                                        )}
                                    />
                                ))}

                            {(metrics?.uptime || [])
                                .filter((metric) => !metricIsWebchat(metric))
                                .map((uptimeMetric, idx) => (
                                    <Uptime key={"_" + idx} metric={uptimeMetric}/>
                                ))}

                            {(metrics?.othersSpec || []).map((v, idx) => (
                                <OtherMetrics
                                    key={"i_" + idx}
                                    metrics={metrics}
                                    metricSpec={v}
                                />
                            ))}
                        </div>
                    )}
                </Paper>

                {metrics.monthlyUptime && <MonthlyUptime data={metrics.monthlyUptime}/>}

            </Container>
        </ResponsiveContext.Provider>
    );
};

export default App;

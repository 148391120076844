const TEXTS = {
    cardHeader: {
        en: "Data from the last 30 minutes",
        es: "Datos en los últimos 30 minutos",
        pt: "Dados nos últimos 30 minutos",
    },
    noReply: {
        en: "Unanswered",
        es: "Sin respuesta",
        pt: "Sem resposta",
    },
    title: {
        en: "Status monitor",
        es: "Monitor de status",
        pt: "Monitor de status",
    },
    lastUpdated: {
        en: "Last update",
        es: "Última actualización",
        pt: "Última atualização",
    },
    percentile95: {
        en: "95th percentile",
        es: "Percentil 95",
        pt: "Percentil 95",
    },
    subTitle: {
        en: "This status monitor tests the bot on each channel with a message every 1 minute, confirming response and time.",
        es: "Este monitor de status prueba el bot en cada canal con un mensaje cada 1 minuto, confirmando respuesta y lapso.",
        pt: "Este monitor de status testa o bot em cada canal com uma mensagem a cada 1 minuto, confirmando a resposta e o tempo.",
    },
    recentLogs: {
        en: "Recent logs",
        es: "Logs recientes",
        pt: "Logs recentes",
    },
    reply: {
        en: "replied in ",
        es: "respondió en ",
        pt: "respondeu em ",
    },
    seconds: {
        en: "seconds",
        es: "segundos",
        pt: "segundos",
    },
    secs: {
        en: "secs",
        es: "segs",
        pt: "segs",
    },
    statusOperative: {
        en: "Operating",
        es: "Operando",
        pt: "Operativo",
    },
    statusUnstable: {
        en: "Unstable",
        es: "Inestable",
        pt: "Instável",
    },
    statusDelays: {
        en: "With delay",
        es: "Con demora",
        pt: "Com atraso",
    },
    uptimeOK: {
        en: "OK",
        es: "OK",
        pt: "OK",
    },
    uptimeNOK: {
        en: "With issues",
        es: "Con problemas",
        pt: "Com problemas",
    },
    metric_user: {
        en: "Time to process an incoming message from the user",
        es: "Tiempo para procesar un mensaje entrante del usuario",
        pt: "Tempo para processar uma mensagem recebida do usuário",
    },
    "metric_operator-msg": {
        en: "Time from agent sending a message until WhatsApp is reached",
        es: "Tiempo desde que un agente escribe hasta que WhatsApp es alcanzado",
        pt: "Tempo desde que um agente escreve até que o WhatsApp seja alcançado",
    },
    noMetrics: {
        en: "No metrics found to display",
        es: "No se encontraron metricas para mostrar",
        pt: "Nenhuma métrica encontrada para exibição",
    },
    "metric_api-send-message": {
        en: "Time from calling the Botmaker API until WhatsApp is reached",
        es: "Tiempo desde que se llama a la API de Botmaker hasta que se alcanza WhatsApp",
        pt: "Tempo desde a chamada da API do Botmaker até que o WhatsApp seja alcançado",
    },
    "metric_web-console-get-customers": {
        en: "Customers retrieval for agents",
        es: "Obtención de clientes para agentes",
        pt: "Recuperação de clientes para agentes",
    },
    "metric_web-console-get-customers_desc": {
        en: "Health check for clients getting customer list in the platform",
        es: "Chequeo de salud para clientes que obtienen la lista de clientes en la plataforma",
        pt: "Verificação de saúde para clientes recebendo lista de clientes na plataforma",
    },
    "metric_webhook-calls-status": {
        en: "Webhook usage",
        es: "Uso de webhook",
        pt: "Uso de webhook"
    },
    "metric_webhook-calls-status_desc": {
        en: "Quantity of webhook calls that are being executed at this time",
        es: "Cantidad de llamadas de webhook que se están ejecutando en este momento",
        pt: "Quantidade de chamadas de webhook que estão sendo executadas neste momento",
    },
    "webhook-calls-status-err": {
        en: "Webhook errors",
        es: "Errores de webhook",
        pt: "Erros de webhook",
    },
    "webhook-calls-status-err_desc": {
        en: "Quantity of webhook calls that are being executed and failed at this time",
        es: "Cantidad de llamadas de webhook que se están ejecutando y fallaron en este momento",
        pt: "Quantidade de chamadas de webhook que estão sendo executadas e falharam neste momento",
    },
    "wap_sent_ok": {
        en: "WhatsApp sent messages",
        es: "Mensajes enviados por WhatsApp",
        pt: "Mensagens enviadas pelo WhatsApp",
    },
    "wap_sent_ok_desc": {
        en: "Quantity of WhatsApp messages that are being sent at this time",
        es: "Cantidad de mensajes de WhatsApp que se están enviando en este momento",
        pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas neste momento",
    },
    "wap_sent_failed": {
        en: "WhatsApp sent messages with problems",
        es: "Mensajes enviados por WhatsApp con problemas",
        pt: "Mensagens enviadas pelo WhatsApp com problemas",
    },
    "wap_sent_failed_desc": {
        en: "Quantity of WhatsApp messages that are being sent and failed at this time",
        es: "Cantidad de mensajes de WhatsApp que se están enviando y fallaron en este momento",
        pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas e falharam neste momento",
    },
    "status_wapp_mon-user": {
        en: "WhatsApp received messages from users",
        es: "Mensajes de WhatsApp recibidos de usuarios",
        pt: "Mensagens do WhatsApp recebidas de usuários"
    },
    "status_wapp_mon-user_desc": {
        en: "Quantity of WhatsApp messages that are being received from users at this time",
        es: "Cantidad de mensajes de WhatsApp que se están recibiendo de usuarios en este momento",
        pt: "Quantidade de mensagens do WhatsApp que estão sendo recebidas de usuários neste momento",
    },
    "status_wapp_mon-api-send-message": {
        en: "WhatsApp sent messages using the Botmaker API",
        es: "Mensajes de WhatsApp enviados usando la API de Botmaker",
        pt: "Mensagens do WhatsApp enviadas usando a API do Botmaker",
    },
    "status_wapp_mon-api-send-message_desc": {
        en: "Quantity of WhatsApp messages that are being sent from systems using the Botmaker API at this time",
        es: "Cantidad de mensajes de WhatsApp que se están enviando desde sistemas usando la API de Botmaker en este momento",
        pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas de sistemas usando a API do Botmaker neste momento",
    },
    "status_wapp_mon-operator-msg": {
        en: "WhatsApp sent messages by agents in the Botmaker Platform",
        es: "Mensajes de WhatsApp enviados por agentes en la Plataforma de Botmaker",
        pt: "Mensagens do WhatsApp enviadas por agentes na Plataforma do Botmaker",
    },
    "status_wapp_mon-operator-msg_desc": {
        en: "Quantity of WhatsApp messages that are being sent from agents using the Botmaker Platform at this time",
        es: "Cantidad de mensajes de WhatsApp que se están enviando desde agentes usando la Plataforma de Botmaker en este momento",
        pt: "Quantidade de mensagens do WhatsApp que estão sendo enviadas de agentes usando a Plataforma do Botmaker neste momento"
    },
    "metric_webhook-calls-status-error": {
        en: "Webhook error",
        es: "Error de webhook",
        pt: "Erro de webhook",
    },
    "metric_api-get-chats": {
        en: "Botmaker API",
        es: "Botmaker API",
        pt: "Botmaker API",
    },
    "metric_api-get-chats_desc": {
        en: "Health check of a client using the Botmaker API",
        es: "Chequeo de salud de un cliente usando la API de Botmaker",
        pt: "Verificação de saúde de um cliente usando a API do Botmaker",
    },
    "metric_webchat-user-message": {
        en: "Webchat",
        es: "Webchat",
        pt: "Webchat",
    },
    "metric_web-console-access": {
        en: "Platform access for agents",
        es: "Acceso a la plataforma para agentes",
        pt: "Acesso à plataforma para agentes",
    },
    "metric_web-console-access_desc": {
        en: "Health check for clients accessing the platform by going to go.botmaker.com",
        es: "Chequeo de salud para clientes que acceden a la plataforma yendo a go.botmaker.com",
        pt: "Verificação de saúde para clientes acessando a plataforma indo para go.botmaker.com",
    },
    "metric_web-console-get-messages": {
        en: "Messages retrieval for agents",
        es: "Obtención de mensajes para agentes",
        pt: "Recuperação de mensagens para agentes",
    },
    "metric_web-console-get-messages_desc": {
        en: "Health check for clients getting messages from a customer in the platform",
        es: "Chequeo de salud para clientes que obtienen mensajes de un cliente en la plataforma",
        pt: "Verificação de saúde para clientes recebendo mensagens de um cliente na plataforma",
    },
    wappApiCallOK: {
        en: "Quantity of WhatsApp API calls per second",
        es: "Cantidad de llamadas a la API de WhatsApp por segundo",
        pt: "Quantidade de chamadas da API do WhatsApp por segundo",
    },
    wappApiCallFailed: {
        en: "With errors",
        es: "Con errores",
        pt: "Com erros",
    },
    webchatIncomeProcessing: {
        en: "Webchat sum of incoming messages by second",
        es: "Webchat suma de mensajes entrantes por segundo",
        pt: "Webchat soma de mensagens recebidas por segundo",
    },
    agentAccessTime: {
        en: "Time for the agent to access the platform",
        es: "Tiempo de acceso del agente a la plataforma",
        pt: "Tempo de acesso do agente à plataforma",
    },
    agentAccessTime_desc: {
        en: "Time that is taking to current agents to access to the platform by going to go.botmaker.com",
        es: "Tiempo que está tomando a los agentes actuales acceder a la plataforma yendo a go.botmaker.com",
        pt: "Tempo que está levando para os agentes atuais acessarem a plataforma indo para go.botmaker.com",
    },
    agentGetMessageTime: {
        en: "Time for the agent to get user messages in the platform",
        es: "Tiempo del agente para obtener mensajes de usuario en la plataforma",
        pt: "Tempo do agente para obter mensagens de usuário na plataforma",
    },
    agentGetMessageTime_desc: {
        en: "Time that is taking to current agents to retrieve messages from a chat in the platform",
        es: "Tiempo que está tomando a los agentes actuales para recuperar mensajes de un chat en la plataforma",
        pt: "Tempo que está levando para os agentes atuais recuperarem mensagens de um chat na plataforma",
    },
    queueWaiting: {
        en: "Approximate quantity of users waiting in all queues",
        es: "Cantidad aproximada de usuarios esperando en todas las colas",
        pt: "Quantidade aproximada de usuários esperando em todas as filas",
    },
    queueWaitingSecs: {
        en: "Approximate time users wait for human agent",
        es: "Tiempo aproximado que los usuarios esperan por un agente humano",
        pt: "Tempo aproximado que os usuários esperam por um agente humano",
    },
    onlineAgents: {
        en: "Approximate quantity of online agents",
        es: "Cantidad aproximada de agentes en línea",
        pt: "Quantidade aproximada de agentes online",
    },
    noMonitor: {
        en: "Your bot is not currently being monitored, please contact the support team if you are interested in activating it.",
        es: "Tu bot no está siendo monitoreado actualmente, por favor contacta al equipo de soporte si estás interesado en activarlo.",
        pt: "Seu bot não está sendo monitorado no momento. Entre em contato com a equipe de suporte se estiver interessado em ativá-lo.",
    },
};
//mensaje enviado/respuesta recibida+despuesta de bot/email envia alerta/demora tanto segundos/link en los clientes que lo tienen

const getLang = () => {
    const browserLang = navigator.language || navigator.userLanguage || "en-US";

    return browserLang.indexOf("-") === -1
        ? browserLang
        : browserLang.split("-")[0];
};

const l = (key, l) => {
    const theLang = l || getLang() || "en";
    const text = TEXTS[key];

    if (!text)
        return null;

    return text[theLang] || null;
};

exports.getLang = getLang;
exports.l = l;

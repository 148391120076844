import moment from "moment";
import {capitalize} from "lodash";
import {l} from "./Lang";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, {retries: 3});
axios.defaults.withCredentials = true;

export const MAX_TIME_MS = 120000;
const STATUS_LABELS = [
    l("statusOperative"),
    l("statusUnstable"),
    l("statusDelays"),
];
export const STATUS_COLOR_TEXTS = ["#44A134", "#DD9320", "#D0021B"];
export const STATUS_COLOR = ["#3dc639", "#FEEDD3", "#F8CCD1"];

const enrich = (channelIn) => {
    const {platform, platformId, channelName} = channelIn;
    let link = "";
    let name = getPlatformName(platform);

    if (platform === "whatsapp") {
        name = platformId;
        link = `https://wa.me/${platformId}`;
    } else if (platform === "messenger") {
        name = channelName || platformId;
        link = `https://m.me/${platformId}`;
    } else if (platform === "webchat") {
        name = channelName || platformId;
        link = `https://storage.googleapis.com/m-infra.appspot.com/public/botmaker/webChatTest.html?id=${platformId}`;
    }
    // if (platform === 'telegram') icon = 'telegram';
    // if (platform === 'twitter') icon = 'twitter';
    // if (platform === 'twitter_tweets') icon = 'twitter';

    // if (platform === 'facebook-page') icon = 'facebook';
    // if (platform === 'sms') icon = 'sms';

    channelIn.name = name;
    channelIn.platformName = getPlatformName(platform);
    channelIn.link = link;
};

const asc = (arr) => arr.sort((a, b) => a - b);

export const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;

    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

export const getStatus = (duration, optProblemsValue) => {
    if (optProblemsValue)
        return duration < 0 || duration > optProblemsValue ? "down" : "operational";

    if (duration > 55000)
        return "down";

    return duration < 10000 ? "operational" : "unstable";
};

export const formatDate = (date) => {
    return moment(date).format("DD-MM-YYYY HH:mm");
};

export const formatDateHHMM = (date) => {
    return moment(date).format("HH:mm");
};

export const getPlatformName = (platformId) => {
    if (platformId === "whatsapp") return "WhatsApp";
    if (platformId === "messenger") return "FB Messenger";
    if (platformId === "facebook-page") return "FB Page";
    if (platformId === "twitter_tweets") return "Tweets";

    return capitalize(platformId);
};

// export const formatHour = (date) => {
//     return 'a las ' + moment(date).format('HH:mm');
// }

export const getAuth = async (authToken, desiredBid, lang) => {
    return axios.post(`${getHost()}/auth`, {authToken, desiredBid, lang});
};

const getHost = () => {
    // return window.location.hostname !== "localhost" // NO COMMMIT!!
    return window.location.hostname === "localhost"
        ? "http://localhost:8080"
        : "https://status.botmaker.app";
};

// export const preparePairDataPoints = (pointsArray, otherPointsArray, maxAxisY) => {
//     const otherPointsMap = (otherPointsArray || [])
//         .reduce((prev, curr) => {
//             const time = moment(curr.iso).format('HH:mm');
//
//             prev[time] = curr;
//
//             return prev;
//         }, {});
//
//     return (pointsArray || [])
//         .map(p => {
//             const time = moment(p.iso).format('HH:mm');
//             const userValueAtTime = otherPointsMap[time] || {v: 0};
//
//             return {
//                 time,
//                 value1: p.v < 0 || p.v > maxAxisY ? maxAxisY : p.v,
//                 value2: userValueAtTime.v < 0 || userValueAtTime.v > maxAxisY ? maxAxisY : userValueAtTime.v
//             };
//         });
// };

export const prepareDataPoints = (pointsArray, maxAxisY) => {
    return (pointsArray || []).map((p) => {
        const time = moment(p.iso).format("HH:mm");

        return {
            time,
            value1: p.v < 0 || p.v > maxAxisY ? maxAxisY : p.v,
        };
    });
};

export const getMax = (chartPoints) => {
    let max = 0;

    chartPoints.forEach((p) => {
        const v = Math.max(p.value1 || 0, p.value2 || 0);

        if (v > max) max = v;
    });

    return Math.round(parseInt(max));
};

export const getMetrics = async (businessId, lang, optAuthToken) => {
    const raw = await axios.post(`${getHost()}/metrics`, {
        desiredBid: businessId,
        authToken: optAuthToken || null,
        lang,
        // authToken: 'e...mA'
    });

    const result = raw.data;
    result.lastRefreshTime = new Date();

    (result.channelMetrics || []).forEach((v) => enrich(v));

    return result;
};

export const formatDuration = (duration) => {
    if (duration === -1 || duration === 120000) return l("noReply");

    const durationAsSeconds = moment.duration(duration).asSeconds();
    const regex2decimals = /(\d*.\d{0,2})/;
    const durationResult = durationAsSeconds.toString().match(regex2decimals)[0];

    return l("reply") + " " + durationResult + " " + l("seconds");
};

export const getStatusRender = (type, v, optProblemsValue) => {
    const mode = getStatus(v, optProblemsValue);
    const index = mode === "operational" ? 0 : mode === "unstable" ? 1 : 2; // else 'down'

    if (type === "label") return STATUS_LABELS[index];

    if (type === "colorText") return STATUS_COLOR_TEXTS[index];

    return STATUS_COLOR[index];
};

// export const formatFromNow = (date) => moment(date).fromNow();

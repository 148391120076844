import React from "react";
import Chip from "@mui/material/Chip";
import { getStatusRender } from "./Utils";

const ChannelStatus = ({ duration, label, color }) => {
  const statusLabel = label || getStatusRender("label", duration);
  // const statusColorText = getStatusRender('colorText', duration);
  const statusColor = color || getStatusRender("color", duration);

  return (
    <Chip
      label={statusLabel}
      className="channel-status-chip"
      style={{
        borderRadius: "3px",
        lineHeight: "10px",
        fontSize: "11px",
        fontFamily: "Roboto",
        padding: "4px 13px",
        height: "24px",
        color: "black",
        backgroundColor: statusColor,
      }}
    />
  );
};
export default ChannelStatus;

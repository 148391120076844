import React, { useContext, useState } from "react";
import Icon from "@mui/material/Icon";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import { formatDuration, getPlatformName } from "./Utils";
import { ResponsiveContext } from "./ResponsiveContext";
import Histogram from "./Histogram";
import ChannelStatus from "./ChannelStatus";

const ChannelLogo = ({ platform }) => {
  let icon = "adjust";

  if (platform === "whatsapp") icon = "whatsapp";
  if (platform === "webchat") icon = "webchat";
  if (platform === "telegram") icon = "telegram";
  if (platform === "twitter") icon = "twitter";
  if (platform === "twitter_tweets") icon = "twitter_tweets";
  if (platform === "messenger") icon = "messenger";
  if (platform === "facebook-page") icon = "fb_logo";
  if (platform === "sms") icon = "sms";

  if (!icon) return <Icon className="channel-logo">{icon}</Icon>;

  const url = `https://storage.googleapis.com/m-infra.appspot.com/public/icons/${platform}.png`;
  const size = platform === "whatsapp" ? 25 : 20;

  return <img alt={platform} src={url} style={{ height: size, width: size }} />;
};

const Channel = ({ channel }) => {
  const { isLargeScreen, isMediumScreen, isExtraSmallScreen, l } =
    useContext(ResponsiveContext);
  const [expanded, setExpanded] = useState(false);

  const ChannelInfo = ({ title, value }) => (
    <div className="channel-info-container">
      <div className="channel-info">
        {value !== l("noReply") ? (
          <div>
            <span>{title}</span>
            <span className="channel-info-value">{value}</span>
          </div>
        ) : (
          <span className="channel-info-value-not-response">{value}</span>
        )}
      </div>
    </div>
  );

  const { platform, lastValidSample } = channel;
  const lastPointDuration = lastValidSample?.v;
  const isRecent = lastValidSample?.isRecent;
  const duration =
    lastPointDuration && isRecent ? formatDuration(lastPointDuration) : "";
  const lastValidPointDuration =
    lastPointDuration && isRecent ? lastPointDuration : -1;
  const showExpansion = isMediumScreen || isLargeScreen;
  const okStatus = channel.status !== "unstable";

  return (
    <Accordion
      elevation={1}
      disableGutters={true}
      expanded={expanded}
      onChange={() => setExpanded((prevStatus) => !prevStatus)}
      className={`${
        okStatus ? "card-ok" : "card-problems"
      } accordion-container`}
    >
      <AccordionSummary
        expandIcon={showExpansion && <ExpandMore />}
        style={{
          cursor: !showExpansion ? "default" : "pointer",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: isExtraSmallScreen ? "0.6em" : "",
            justifyContent: "space-between",
            flexDirection: isExtraSmallScreen ? "column" : "row",
            width: "100%",
          }}
        >
          <div className="channel-name-container">
            <div className="channel-name">
              <ChannelLogo platform={platform} />
              <span className="platform-name">{getPlatformName(platform)}</span>
            </div>
          </div>

          <div className="channel-link">
            <a href={channel.link} target="_blank" rel="noreferrer">
              {channel.name}
            </a>
          </div>

          <ChannelStatus duration={lastValidPointDuration} />

          {isLargeScreen && <ChannelInfo value={duration} />}

          {/*<div className="tooltip">*/}
          {/*    <span className="tooltiptext">Tiempo de respuesta ultimos 30 minutos</span>*/}
          {/*    <Histogram points={channel.points} width='80' height='50' maxAxisY={MAX_TIME_MS}*/}
          {/*               fontSize={5} showLegend={false} showTooltip={false}/>*/}
          {/*</div>*/}
        </span>
      </AccordionSummary>

      {showExpansion && (
        <AccordionDetails>
          <Histogram channel={channel} />
        </AccordionDetails>
      )}
    </Accordion>
  );
};
export default Channel;

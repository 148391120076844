import { useContext, useState } from "react";
import { ResponsiveContext } from "../ResponsiveContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UptimeChart from "./charts/UptimeChart";
import { formatDateHHMM, STATUS_COLOR } from "../Utils";
import ChannelStatus from "../ChannelStatus";
import "./Uptime.css";

const PERCENT_TO_FAIL = 0.95;

const ChannelWebchat = ({ channel, uptime }) => {
  const [expanded, setExpanded] = useState(false);
  const { isExtraSmallScreen, l } = useContext(ResponsiveContext);

  if (!channel || !uptime) return null;
  const { label, points } = uptime;
  const { channelName } = channel;

  // TODO: Change this if we have multiple charts.. analyze all of them.
  const okAmount = points.filter((obj) => obj.v === 1).length;
  const okStatus = okAmount >= PERCENT_TO_FAIL * points.length;

  points.forEach((obj) => {
    obj.dateToShow = formatDateHHMM(obj.iso);
    obj.one = 1;
  });

  return (
    <Accordion
      elevation={1}
      disableGutters={true}
      expanded={!isExtraSmallScreen && expanded}
      onChange={() => setExpanded((prevStatus) => !prevStatus)}
      className={`${
        okStatus ? "card-ok" : "card-problems"
      } accordion-container`}
    >
      <AccordionSummary
        expandIcon={!isExtraSmallScreen && <ExpandMore />}
        style={{
          cursor: isExtraSmallScreen ? "default" : "pointer",
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: isExtraSmallScreen ? "0.6em" : "",
            justifyContent: "space-between",
            width: "100%",
            marginRight: isExtraSmallScreen ? "" : "16px",
            flexDirection: isExtraSmallScreen ? "column" : "row",
          }}
        >
          <div className="box-title">
            {okStatus ? (
              <CheckCircle className="ok-color" />
            ) : (
              <Warning className="problems-color" />
            )}

            <span className="bold">{`${label} - ${channelName}`}</span>
          </div>

          <ChannelStatus
            label={okStatus ? l("uptimeOK") : l("uptimeNOK")}
            color={STATUS_COLOR[okStatus ? 0 : 2]}
          />
        </span>
      </AccordionSummary>

      {expanded && (
        <AccordionDetails>
          <div className="accordion-details">
            <div className="accordion-details-title">
              <UptimeChart
                points={points}
                label={label}
                channelName={channel.channelName}
              />
            </div>
          </div>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default ChannelWebchat;
